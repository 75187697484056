import React from "react";
import RegisterNewUser from "../components/RegisterNewUser";

function NewUser() {
  return (
    <>
      <RegisterNewUser />
    </>
  );
}

export default NewUser;
