import React from "react";
import InvoiceList from "../components/invoiceList";
import '../App.css';


function Invoices() {
  return (
    <div className="invoice-body-list">
      <InvoiceList />
    </div>
  );
}

export default Invoices;
